<template>
  <el-dialog :title="title"
             :close-on-click-modal="false"
             append-to-body
             :visible.sync="visible">
    <el-table :data="tableData.filter(data => !search || data.dictValue.toLowerCase().includes(search.toLowerCase()))"
              style="width: 100%"
              height="450">
      <el-table-column fixed
                       prop="dictValue"
                       label="参数"
                       width="150" />
      <el-table-column label="可读权限">
        <template slot-scope="scope">
          <el-tag v-for="tag in scope.row.readOnlyUsers"
                  :key="tag.id"
                  class="mx-1"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(scope.row,tag,'readOnly')">
            {{ tag.name }}
          </el-tag>
<!--          <el-button icon="el-icon-search"-->
<!--                     style="margin-left:10px"-->
<!--                     circle-->
<!--                     @click="addUser(scope.row.id,'readOnly')"></el-button>-->

        </template>
      </el-table-column>
      <el-table-column label="编辑权限">
        <template slot-scope="scope">
          <el-tag v-for="tag in scope.row.editUsers"
                  :key="tag.id"
                  class="mx-1"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(scope.row,tag,'edit')">
            {{ tag.name }}
          </el-tag>
<!--          <el-button icon="el-icon-search"-->
<!--                     style="margin-left:10px"-->
<!--                     circle-->
<!--                     @click="addUser(scope.row.id,'edit')"></el-button>-->

        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button icon="el-icon-view"
                     circle @click="addUser(scope.row.id,'readOnly')"></el-button>
          <el-button icon="el-icon-edit"
                     circle @click="addUser(scope.row.id,'edit')"></el-button>
        </template>
      </el-table-column>
       <el-table-column>
      <template slot="header" slot-scope="scope">
        <el-input
          v-model="search"
          size="mini"
          placeholder="输入关键字搜索"/>
      </template>
    </el-table-column>
    </el-table>
    <user-select ref="userSelect"
                 @doSubmit="selectUsersToRole"></user-select>
    <span slot="footer"
          class="dialog-footer">
      <el-button size="small"
                 @click="visible = false">取消</el-button>
      <el-button size="small"
                 @click="oneClickAdd('readOnly')" v-if="tableData.length>0">一键添加可读权限</el-button>
      <el-button size="small"
                 @click="oneClickAdd('edit')" v-if="tableData.length>0">一键添加编辑权限</el-button>

      <el-button size="small"
                 type="primary"
                 @click="doSubmit()"
                 v-if="tableData.length>0"
                 v-noMoreClick>确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  declarationFormTemplateAuthorityList,
  declarationFormTemplateAuthorityUpdate,
} from '@/api/declaration/declarationFormTemplate'
import UserSelect from '@/components/userSelect/UserSelectDialog'
export default {
  components: {
    UserSelect,
  },
  data() {
    return {
      visible: false,
      loading: false,
      title: '',
      tableData: [],
      rowId: '',
      type: '',
      reading: false,
      edit: false,search:''
    }
  },
  methods: {
    /**
     * 初始化
     */
    init(templateId) {
      this.search = ''
      declarationFormTemplateAuthorityList(templateId).then((res) => {
        if (res && res.status) {
          this.tableData = res.data
          this.visible = true
        }
      })
    },
    /**
     * 删除权限
     */
    handleClose(row, tag, type) {
      console.log('删除的编号', tag.id)
      if (type === 'readOnly') {
        row.readOnlyUsers.splice(row.readOnlyUsers.indexOf(tag), 1)
        row.editUsers = row.editUsers.filter((item) => {
          return item.id != tag.id
        })
      } else {
        row.editUsers.splice(row.editUsers.indexOf(tag), 1)
      }
    },
    /**
     * 添加权限
     * 如果是添加 edit 编辑权限时, 同时添加查看权限
     */
    selectUsersToRole(users) {
      // 判断 当前按钮是否为一键新增的功能
      if (this.reading) {
        this.tableData.map((row) => {
          var user = []
          user = row.readOnlyUsers
          // user.concat(users)
          users.forEach(function (value, index) {
            user.push(value)
          })

          row.readOnlyUsers = this.unique(user)
        })

        this.reading = false
      } else if (this.edit) {
        this.tableData.map((row) => {
          var user = []
          user = row.readOnlyUsers
          users.forEach(function (value, index) {
            user.push(value)
          })

          row.readOnlyUsers = this.unique(user)

          user = row.editUsers
          users.forEach(function (value, index) {
            user.push(value)
          })

          row.editUsers = this.unique(user)
        })
        this.edit = false
      } else {
        this.tableData.map((row) => {
          if (this.rowId === row.id) {
            var user = []
            if (this.type === 'readOnly') {
              user = row.readOnlyUsers
              // user.concat(users)
              users.forEach(function (value, index) {
                user.push(value)
              })

              row.readOnlyUsers = this.unique(user)
            } else {
              user = row.readOnlyUsers
              // user.concat(users)
              users.forEach(function (value, index) {
                user.push(value)
              })

              row.readOnlyUsers = this.unique(user)

              user = row.editUsers
              users.forEach(function (value, index) {
                user.push(value)
              })

              row.editUsers = this.unique(user)
            }
          }
        })
      }
    },
    unique(arr) {
      var newArr = []
      for (var i = 0; i < arr.length; i++) {
        var flag = false
        for (let index = 0; index < newArr.length; index++) {
          const element = newArr[index]
          if (element.id === arr[i].id) {
            flag = true
          }
        }
        if (!flag) {
          newArr.push(arr[i])
        }
      }
      console.log(newArr)

      return newArr
    },
    /**
     * 一键添加功能
     */
    oneClickAdd(type) {
      if (type === 'readOnly') {
        this.reading = true
      } else {
        this.edit = true
      }
      this.$refs.userSelect.init(null)
    },

    addUser(id, type) {
      this.rowId = id
      this.type = type
      this.$refs.userSelect.init()
    },
    doSubmit() {
      declarationFormTemplateAuthorityUpdate(this.tableData).then((res) => {
        if (res && res.status) {
          this.$message.success('提交成功')
          this.visible = false
        } else {
          this.$message.success('提交失败')
        }
      })
    },
  },
}
</script>

<style scoped>
  .el-tag {
    height: auto !important;
    white-space: normal;
  }
</style>
